.content_menu{
    display: flex;
    flex-direction: column;
    background-color: #556e74;
    width: 190px;
    transition: width 0.2s linear;
}
.menu_item{
    display: flex;
    align-items: center;
    gap: 5px;
    text-transform: uppercase;
    color: #fff;
    width: 100%;
    min-height: 40px;
    height: max-content;
    padding: 10px;
    white-space: nowrap;
}
.active{
    background-color: #73898f;
}

.menu_item:hover{
    background-color: #73898f;
    cursor: pointer;
}
.right_arrow{
    justify-self: end;
}
.menu_extend{
    position: relative;
    display: flex;
    justify-content: space-between;
}
.menu_extend_second .menu_item{
    white-space: pre-wrap;
 }
.left_side{
    display: flex;
    gap: inherit;
}
.menu_extend_second{
    position: absolute;
    top: 0;
    left: 100%;
    color: black;
    width: 180px;
    opacity: 0;
    transition: all 0.2s linear;
    visibility: hidden;
 }
 .menu_extend:hover > .menu_extend_second{
    opacity: 1;
    transition: all 0.2s linear;
    visibility: visible;
}

@media (max-width: 750px) {
    .item_inner{
        position: absolute;
        opacity: 0;
        transition: opacity 0.1s ease;
    }
    .menu_item svg{
        width: 35px;
        height: 35px;
    }
    .right_arrow{
        display: none;
    }
    .content_menu{
        position: absolute;
        height: 100%;
        width: 50px;
    }
    .menu__show{
        width: 190px;   
        transition: width 0.2s linear;
        z-index: 999;
    }
    .menu__show .menu_item svg{ 
        width: 1em;
        height: 1em;
    }
    .menu__show .item_inner{ 
        position: initial;
        opacity: 1;
        transition: opacity 0.2s linear;
        min-width: 140px;
    }
    .menu_extend_second{
        top: 0;
        left: 50px;
        min-width: 150px;
        visibility: hidden;
     }
     .content_menu .menu_extend_second{
        width: inherit;
        height: max-content;
     }
     .menu_extend:hover .menu_extend_second .item_inner {
        opacity: 1;
        min-width: 140px;
     } 
     .menu__show .menu_extend_second{
        top: 0;
        left: 190px;
     }
}