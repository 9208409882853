.menu__title{
    display: flex;
    width: 100%;
    height: 3rem;
    background-color: #3c454f;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    color:#fff;
}
.menu_list{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.menu{
    background-color: #3c454f;
}

.menu_burger{
    position: relative;
    display: flex;
    gap: 10px;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 750px) {
   .menu_burger svg{
        width: 35px;
        height: 35px;
   }
    .menu_burger_text{
        display: none;
        
    }
   
}
@media (max-width: 500px) {
    .menu__title{
        font-size: 0.8rem;
    }
   
}
